import { reactive } from 'vue'
import { CodeVerification, Button } from 'account-ui-components'
import ModalAuthenticatorAppHelp from '@/components/_modals/ModalAuthenticatorAppHelp/ModalAuthenticatorAppHelp.vue'

export default {
  name: 'VerifyMfaEmailCode',

  props: {
    loading: {
      type: Object,
      required: true
    },
    lockout: {
      type: Boolean,
      default: false
    },
    timeLeft: {
      type: Number,
      default: 0
    },
    email: {
      type: String,
      default: ''
    },
    errors: {
      type: Object,
      required: true
    },
    clearVerificationError: {
      type: Function,
      required: true
    },
    onUseAuthenticatorAppClick: {
      type: Function,
      required: true
    },
    onVerifyEmailCodeConfirm: {
      type: Function,
      required: true
    },
    onSendNewCode: {
      type: Function,
      required: true
    }
  },

  components: {
    Button,
    CodeVerification,
    ModalAuthenticatorAppHelp
  },

  setup() {
    const modals = reactive({
      help: false
    })

    const toggleModal = (modal) => {
      modals[modal] = !modals[modal]
    }

    return {
      modals,
      toggleModal
    }
  }
}
