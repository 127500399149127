<template>
  <div class="verify-mfa-email-code">
    <div class="verify-mfa-email-code__title">Enter code sent to your email</div>

    <div class="verify-mfa-email-code__text">
      Please enter the code we have sent to the email {{email}}.
      <br />
      The code will expire after 10 minutes.
    </div>

    <div class="verify-mfa-email-code__code-container">
      <code-verification
        :loading="loading"
        :error="errors.verification"
        hyphen="true"
        :on-change="clearVerificationError"
        :on-focus="clearVerificationError"
        :on-verification="onVerifyEmailCodeConfirm"
        :disabled="lockout"
      ></code-verification>
      <div class="verify-mfa-email-code__counter-container">
        <div class="verify-mfa-email-code__button-container">
          <Button
            v-if="lockout"
            :disabled="timeLeft > 0"
            auto-qa-id="verify-mfa-email-code-send-new-code-button"
            :onClick="onSendNewCode"
            variant="outlined"
          >
            Send New Code
          </Button>
        </div>
        <div
          v-if="timeLeft > 0"
          class="verify-mfa-email-code__counter"
        >
          {{ timeLeft }}s
        </div>
      </div>
    </div>

    <div class="verify-mfa-email-code__link-holder">
      <div class="verify-mfa-email-code__link-holder">
        <div
          class="verify-mfa-email-code__link"
          @click="onUseAuthenticatorAppClick"
        >
          Use authenticator app instead
        </div>
      </div>
    </div>

    <modal-authenticator-app-help
      v-if="modals.help"
      :on-close="
        () => {
          toggleModal('help')
        }
      "
    ></modal-authenticator-app-help>
  </div>
</template>

<script src="./VerifyMfaEmailCode.js"></script>

<style src="./VerifyMfaEmailCode.scss" lang="scss" scoped></style>
