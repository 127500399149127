import { ref, reactive, computed, onBeforeMount } from 'vue'
import { Input, PasswordInput, Link } from 'account-ui-components'
import Api from '@/api'
import config from '@/api/config'
import { getEnv, parseCookie } from '@/helpers'
import {
  validateField,
  validateRequired,
  validateLength,
  validateName,
  validateEmail,
  validateEmailAvailability,
  validatePasswordComplexity,
  validateCaptchaTokenExists,
  validateCaptchaTokenExpired
} from '@/helpers/validators'
import Preloader from '@/uikit/Preloader/Preloader.vue'
import Button from '@/uikit/Button/Button.vue'
import Snackbar from '@/uikit/Snackbar/Snackbar.vue'
import Page from '@/components/Page/Page.vue'
import ExternalLogin from '@/components/ExternalLogin/ExternalLogin.vue'
import RegistrationVerifyWithShortCode from '@/components/RegistrationVerifyWithShortCode/RegistrationVerifyWithShortCode.vue'
import Captcha from '@/components/Captcha/Captcha.vue'
import platformsIcon from '@/assets/img/all-platforms-account.svg'

export default {
  name: 'Registration',

  props: {
    mtCaptchaTestKey: {
      type: String,
      default: null
    },
    providers: {
      type: Array,
      default: null
    }
  },

  components: {
    Link,
    Preloader,
    Input,
    PasswordInput,
    Button,
    Snackbar,
    Page,
    ExternalLogin,
    RegistrationVerifyWithShortCode,
    Captcha
  },

  setup() {
    const images = {
      platformsIcon
    }
    const urlParams = new URLSearchParams(window.location.search)
    const companyId = urlParams.get('companyid')
    const id = urlParams.get('userId')
    const emailParam = urlParams.get('email')
    const platform = urlParams.get('platform')
    const code = urlParams.get('code')
    const env = computed(() => getEnv())
    const validate3ShapeDomain = (value) => {
      const regex = /\w+(?:[+.\-\\']\w+)*@3shape\.com/
      if (regex.test(value)) {
        return `
          Your domain is requiring you to use Azure AD SSO.
          You can not register an account manually.
          Login with your Azure AD credentials instead.
        `
      }
      return ''
    }
    const validators = {
      firstName: [validateRequired, (value) => validateLength(value, 1, 50), validateName],
      lastName: [validateRequired, (value) => validateLength(value, 1, 50), validateName],
      email: [validateRequired, validateEmail, validate3ShapeDomain],
      password: [
        validateRequired,
        (value) => validateLength(value, 8, 50),
        validatePasswordComplexity
      ]
    }
    const isLoading = ref(false)
    const captchaToken = ref(null)
    const userId = ref(null)
    const showSnackbar = ref(false)
    const fields = reactive({
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      registrationPlatform: ''
    })
    const errors = reactive({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      captcha: ''
    })
    const SSODocumentationLink =
      'https://support.3shape.com/en_US/products-3shape-unite-3shape-account-how-to/enabling-microsoft-single-single-on-for-3shape-account#registration-with-microsoft-account-0'

    if (id) {
      userId.value = id
    }

    if (emailParam) {
      fields.email = emailParam
    }
    if (platform) {
      fields.registrationPlatform = platform
    }

    onBeforeMount(() => {
      if (document.cookie) {
        const cookie = parseCookie(document.cookie)
        const session = cookie['idsrv.session']

        if (session) {
          const { profile } = config.hosts[env.value]

          window.location.href = profile
        }
      }
    })

    const toggleSnackbar = () => {
      showSnackbar.value = !showSnackbar.value
    }

    const validateUserEmailAvailability = async (email) => {
      const emailAvailabilityError = await validateEmailAvailability(email)

      if (emailAvailabilityError) {
        errors.email = emailAvailabilityError
      }

      return emailAvailabilityError
    }

    const onInputChange = (event) => {
      const { value, name } = event.target

      fields[name] = value

      if (errors[name]) {
        errors[name] = ''
      }
    }

    const onInputBlur = async (event) => {
      const { value, name } = event.target

      if (fields[name] !== null) {
        const error = validateField(value, validators[name])
        if (error) {
          errors[name] = error
        }
      }

      if (name === 'email' && !errors.email) {
        await validateUserEmailAvailability(value)
      }
    }

    const onCaptchaUpdated = (token) => {
      captchaToken.value = token
      errors.captcha = validateField(captchaToken.value, validateCaptchaTokenExpired)
    }

    const validateCaptcha = () => {
      const error = validateField(captchaToken.value, validateCaptchaTokenExists)
      if (error) {
        errors.captcha = error
      }
      return error !== ''
    }

    const validateUserData = async () => {
      Object.keys(fields).forEach((field) => {
        const error = validateField(fields[field], validators[field])

        if (error) {
          errors[field] = error
        }
      })

      await validateUserEmailAvailability(fields.email)
    }

    const createAccount = async () => {
      isLoading.value = true
      await validateUserData()

      let isError = Object.values(errors).some((boom) => boom)

      if (!isError && validateCaptcha()) {
        isError = true
      }

      if (!isError) {
        fields.lastName = fields.lastName.trim()
        fields.firstName = fields.firstName.trim()

        try {
          const { data } = await Api.createAccount(fields, code, companyId, captchaToken.value)

          if (companyId) {
            const { profile } = config.hosts[env.value]

            window.location.href = `/account/mfa?showSkipButton=true&redirectUrl=${profile}/account-activation`
          } else {
            userId.value = data.userId
            urlParams.set('userId', data.userId)
            urlParams.set('email', data.email)
            window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
          }
        } catch (error) {
          isLoading.value = false
          window.mtcaptcha.resetUI()

          if (!showSnackbar.value) {
            toggleSnackbar()
          }
        }
      } else {
        isLoading.value = false
      }
    }

    const onInputKeyPress = ({ keyCode }) => {
      const enterKey = keyCode === 13

      if (enterKey) {
        createAccount()
      }
    }

    return {
      images,
      platform,
      isLoading,
      showSnackbar,
      userId,
      companyId,
      SSODocumentationLink,
      fields,
      errors,
      onCaptchaUpdated,
      toggleSnackbar,
      onInputChange,
      onInputBlur,
      onInputKeyPress,
      createAccount
    }
  }
}
