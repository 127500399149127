import axios from 'axios'

const Api = {
  makeRequest({ method = 'GET', url, data }) {
    const options = {
      method,
      url,
      data
    }

    return axios(options)
  },

  checkEmail(email) {
    return this.makeRequest({
      method: 'HEAD',
      url: `/registration/users/${email}`
    })
  },

  createAccount(userData, code, companyId, captchaToken) {
    const data = {
      user: userData
    }

    if (companyId) {
      data.companyId = companyId
    }
    if (code) {
      data.code = code
    }

    data.captchaToken = captchaToken

    return this.makeRequest({
      method: 'POST',
      url: '/registration/user',
      data
    })
  },

  verifyAccountWithShortCode(userId, shortCode) {
    const errorString = 'Error - Invalid link'
    return this.makeRequest({
      method: 'GET',
      url: `/Account/Activate/${userId}/${shortCode}`
    })
      .then((response) => {
        const responseString = response.data
        if (responseString.includes(errorString)) {
          throw new Error(errorString)
        }
        return responseString
      })
      .catch((error) => {
        throw error
      })
  },

  verifyForgotPasswordCode(email, shortCode) {
    const errorString = 'Error - Invalid link'
    return this.makeRequest({
      method: 'GET',
      url: `/Account/ResetPassword?email=${email}&code=${shortCode}`
    })
      .then((response) => {
        const responseString = response.data
        if (responseString.includes(errorString)) {
          throw new Error(errorString)
        }
        return responseString
      })
      .catch((error) => {
        throw error
      })
  },

  activateMfa(code) {
    const errorString = 'Error - Invalid link'

    const data = { code }

    return this.makeRequest({
      method: 'PUT',
      url: `/api/v1/users/mfa/enable`,
      data
    })
      .then((response) => {
        const responseString = response.data
        if (responseString.includes(errorString)) {
          throw new Error(errorString)
        }
        return responseString
      })
      .catch((error) => {
        throw error
      })
  },

  processTotpCode(code) {
    const errorString = 'Error - Invalid link'

    const data = { code }

    return this.makeRequest({
      method: 'POST',
      url: `/Account/mfa/processTotpCode`,
      data
    })
      .then((response) => {
        const responseString = response.data
        if (responseString.includes(errorString)) {
          throw new Error(errorString)
        }
        return responseString
      })
      .catch((error) => {
        throw error
      })
  },

  processMfaEmailCode(code) {
    const errorString = 'Error - Invalid link'

    const data = { code }

    return this.makeRequest({
      method: 'POST',
      url: `/Account/mfa/processEmailCode`,
      data
    })
      .then((response) => {
        const responseString = response.data
        if (responseString.includes(errorString)) {
          throw new Error(errorString)
        }
        return responseString
      })
      .catch((error) => {
        throw error
      })
  },

  getLoginProvider(username) {
    return this.makeRequest({
      method: 'GET',
      url: `/api/v1/UserLogin/${username}/LoginProvider`
    })
  },

  sendNewCode(email, antiForgeryToken) {
    const bodyFormData = new FormData()
    bodyFormData.append('email', email)
    bodyFormData.append('__RequestVerificationToken', antiForgeryToken)

    return this.makeRequest({
      method: 'POST',
      url: '/Account/ForgotPassword',
      data: bodyFormData
    })
  },

  sendMfaEmailCode() {
    return this.makeRequest({
      method: 'GET',
      url: '/api/v1/mfa/email'
    })
  }
}

export default Api
