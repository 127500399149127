import { onMounted, reactive, ref } from 'vue';
import Api from '@/api';
import Page from '@/components/Page/Page.vue';
import VerifyMfaCode from '@/components/VerifyMfaCode/VerifyMfaCode.vue';
import VerifyMfaEmailCode from '@/components/VerifyMfaEmailCode/VerifyMfaEmailCode.vue';

export default {
  name: 'LoginMfa',

  components: {
    Page,
    VerifyMfaCode,
    VerifyMfaEmailCode
  },

  setup() {
    const loading = reactive({
      verification: false,
      resend: false
    })
    const errors = reactive({
      verification: ''
    })
    const email = ref('')
    const mfaType = ref('authenticator')
    const lockout = ref(false)
    const timeLeft = ref(0)

    onMounted(() => {
      const storageEmail = sessionStorage.getItem('username')

      if (storageEmail) {
        email.value = storageEmail
      }
    })

    const startCountdown = () => {
      const timer = setInterval(() => {
        if (timeLeft.value > 0) {
          timeLeft.value -= 1
        } else {
          clearInterval(timer)
        }
      }, 1000)
    }

    const clearVerificationError = () => {
      if (errors.verification) {
        errors.verification = ''
      }
    }

    const onSendMfaEmailCodeClick = async () => {
      loading.resend = true
      await Api.sendMfaEmailCode()
      mfaType.value = 'email'
      loading.resend = false
    }

    const onUseAuthenticatorAppClick= async () => {
      mfaType.value = 'authenticator'
    }

    const onVerifyConfirm = async (totpCode) => {
      loading.verification = true

      try {
        await Api.processTotpCode(totpCode)

        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        window.location.href = params.get('returnUrl')
      } catch (error) {
        errors.verification = 'Incorrect code. Enter the code again.'
        loading.verification = false
      }
    }

    const onVerifyEmailCodeConfirm = async (code) => {
      loading.verification = true

      try {
        await Api.processMfaEmailCode(code)

        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        window.location.href = params.get('returnUrl')
      } catch (err) {
        if (err.response.status === 429) {
          lockout.value = true
          timeLeft.value = 30
          errors.verification =
            'Incorrect code. The previous code is now invalid. Send and enter the new code.'
          startCountdown()
        } else if (err.response.data.includes('expired')) {
          lockout.value = true
          errors.verification =
            'The previous code is now invalid. Send and enter the new code.'
        } else {
          errors.verification = 'Incorrect code. Enter the code again.'
        }
        loading.verification = false
      }
    }

    const onSendNewCode = async (event) => {
      loading.resend = true
      await Api.sendMfaEmailCode()
      lockout.value = false
      loading.resend = false
      event.preventDefault()
    }

    return {
      loading,
      errors,
      mfaType,
      lockout,
      timeLeft,
      email,
      clearVerificationError,
      onVerifyConfirm,
      onVerifyEmailCodeConfirm,
      onSendMfaEmailCodeClick,
      onUseAuthenticatorAppClick,
      onSendNewCode
    }
  }
}
