<template>
  <Page>
    <div class="multi-factor-authentication">
      <div
        v-if="step === 'getStarted'"
        class="multi-factor-authentication__container"
      >
        <div class="multi-factor-authentication__title">
          Protect your account with two-step verification
        </div>
        <div class="multi-factor-authentication__text">
          Each time you sign in to 3Shape Account, you will need your password and a verification
          code.
        </div>
        <div class="multi-factor-authentication__items-container">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="multi-factor-authentication__item"
          >
            <div class="multi-factor-authentication__item-icon-container">
              <img
                :src="item.icon"
                alt=""
              />
            </div>
            <div>
              <div class="multi-factor-authentication__item-title">
                {{ item.title }}
              </div>
              <div class="multi-factor-authentication__item-text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="multi-factor-authentication__button-container">
          <div class="multi-factor-authentication__button-holder">
            <div
              v-if="showSkipButton"
              class="multi-factor-authentication__skip-link"
              :onClick="onSkipClick"
            >
              Skip
            </div>

            <Button
              v-else
              variant="outlined"
              :onClick="onBackClick"
            >
              Back
            </Button>
          </div>

          <Button
            :onClick="
              () => {
                changeStep('download')
              }
            "
          >
            Get Started
          </Button>
        </div>
      </div>

      <download-authenticator-app
        v-else-if="step === 'download'"
        :on-back-click="
          () => {
            changeStep('getStarted')
          }
        "
        :on-next-click="
          () => {
            changeStep('link')
          }
        "
      ></download-authenticator-app>

      <link-account-to-authenticator-app
        v-else-if="step === 'link'"
        :qr-code-data-uri="qrCodeDataUri"
        :multi-factor-key="multiFactorKey"
        :on-back-click="
          () => {
            changeStep('download')
          }
        "
        :on-next-click="
          () => {
            changeStep('code')
          }
        "
      ></link-account-to-authenticator-app>

      <verify-mfa-code
        v-else-if="step === 'code'"
        :loading="loading"
        :errors="errors"
        :clear-verification-error="clearVerificationError"
        :on-verify-confirm="onVerifyConfirm"
        :on-send-email-code-click="onSendMfaEmailCode"
      ></verify-mfa-code>
    </div>
  </Page>
</template>

<script src="./MultiFactorAuthentication.js"></script>

<style src="./MultiFactorAuthentication.scss" lang="scss" scoped></style>
