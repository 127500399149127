<template>
  <div class="pdf-viewer">
    <div
      v-if="loading.library || loading.document"
      class="pdf-viewer__preloader-container"
    >
      <Preloader />
    </div>

    <component
      v-if="vuePdfEmbed"
      :is="vuePdfEmbed"
      :source="pdfUrl"
      text-layer
      annotation-layer
      @loaded="handleDocumentLoad"
    />
  </div>
</template>

<script src="./PdfViewer.js"></script>

<style src="./PdfViewer.scss" lang="scss" scoped></style>
