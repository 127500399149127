<template>
  <Page>
    <div class="registration">
      <RegistrationVerifyWithShortCode
        v-if="userId"
        :userId="userId"
        :email="fields.email"
      />

      <template v-else>
        <div class="registration__title">Create your 3Shape Account</div>

        <div class="registration__form-container">
          <div class="registration__form">
            <div
              v-if="providers && providers.length"
              class="registration__external-login-container"
            >
              <ExternalLogin
                :providers="providers"
                :platform="platform"
              />

              <div class="registration__external-login-sso-link-container">
                <Link
                  :href="SSODocumentationLink"
                  target="_blank"
                  data-auto-qa-id="how-to-enable-sso-link"
                >
                  How to enable SSO with Microsoft
                </Link>
              </div>

              <div class="registration__external-login-text-container">
                <div class="registration__external-login-text">or</div>
              </div>
            </div>

            <div
              v-if="!platform"
              class="registration__text"
            >
              <div class="registration__text-highlighted">Register with email.</div>

              Already have an account?

              <a
                class="registration__sign-in-link"
                href="/account/login"
                data-auto-qa-id="sign-in-link"
              >
                Sign in
              </a>
            </div>

            <div class="registration__container">
              <div class="registration__input-container">
                <Input
                  label="First name"
                  name="firstName"
                  auto-qa-id="first-name-input"
                  required
                  autocomplete="off"
                  :value="fields.firstName"
                  :error="errors.firstName"
                  :onChange="onInputChange"
                  :onBlur="onInputBlur"
                  :onKeyPress="onInputKeyPress"
                />
              </div>

              <div class="registration__input-container">
                <Input
                  label="Last name"
                  name="lastName"
                  auto-qa-id="last-name-input"
                  required
                  autocomplete="off"
                  :value="fields.lastName"
                  :error="errors.lastName"
                  :onChange="onInputChange"
                  :onBlur="onInputBlur"
                  :onKeyPress="onInputKeyPress"
                />
              </div>

              <div class="registration__input-container">
                <Input
                  label="Email"
                  name="email"
                  auto-qa-id="email-input"
                  type="email"
                  required
                  autocomplete="off"
                  :disabled="!!companyId"
                  :tabindex="!!companyId ? '-1' : null"
                  :value="fields.email"
                  :error="errors.email"
                  :onChange="onInputChange"
                  :onBlur="onInputBlur"
                  :onKeyPress="onInputKeyPress"
                />
              </div>

              <div class="registration__input-container">
                <PasswordInput
                  name="password"
                  auto-qa-id="password-input"
                  hint="Password must be at least 8 characters with the mix of letters, numbers & symbols."
                  required
                  disable-autocomplete
                  :value="fields.password"
                  :error="errors.password"
                  :onChange="onInputChange"
                  :onBlur="onInputBlur"
                  :onKeyPress="onInputKeyPress"
                />
              </div>
              <div class="registration__captcha-container">
                <Captcha
                  :error="errors.captcha"
                  :onVerificationChanged="onCaptchaUpdated"
                  :mtCaptchaTestKey="mtCaptchaTestKey"
                />
              </div>
            </div>

            <div class="registration__button-container">
              <Button
                v-show="!isLoading"
                block
                auto-qa-id="create-account-button"
                :onClick="createAccount"
              >
                Create my account
              </Button>

              <Preloader v-show="isLoading" />
            </div>

            <div class="registration__policy-text">
              Please find more about processing personal data by 3Shape and about your rights in our
              <a
                class="registration__policy-link"
                href="https://www.3shape.com/en/privacy-policy"
                target="_blank"
                data-auto-qa-id="privacy-policy-link"
              >
                Privacy Policy
              </a>
              &
              <a
                class="registration__policy-link"
                href="https://www.3shape.com/en/cookies-policy"
                target="_blank"
                data-auto-qa-id="cookies-policy-link"
              >
                Cookies Policy
              </a>
              .
            </div>
          </div>

          <div class="registration__msg">
            <img
              class="registration__msg-img"
              :src="images.platformsIcon"
              alt=""
            />

            <div class="registration__msg-text">
              One
              <div class="registration__msg-highlighted-text">3Shape Account</div>
              for all platforms.
            </div>
          </div>
        </div>

        <Snackbar
          v-if="showSnackbar"
          auto-qa-id="error-notification"
          message="Something went wrong, please try again"
          theme="red"
          :onClose="toggleSnackbar"
        />
      </template>
    </div>
  </Page>
</template>

<script src="./Registration.js"></script>

<style src="./Registration.scss" lang="scss" scoped></style>
