<template>
  <Page>
    <div class="login-mfa">
      <verify-mfa-code
        v-if="mfaType === 'authenticator'"
        :loading="loading"
        :errors="errors"
        :clear-verification-error="clearVerificationError"
        :on-verify-confirm="onVerifyConfirm"
        :on-send-mfa-email-code-click="onSendMfaEmailCodeClick"
      ></verify-mfa-code>
      <verify-mfa-email-code
        v-if="mfaType === 'email'"
        :loading="loading"
        :errors="errors"
        :lockout="lockout"
        :time-left="timeLeft"
        :email="email"
        :clear-verification-error="clearVerificationError"
        :on-verify-email-code-confirm="onVerifyEmailCodeConfirm"
        :on-use-authenticator-app-click="onUseAuthenticatorAppClick"
        :on-send-new-code="onSendNewCode"
      ></verify-mfa-email-code>
    </div>
  </Page>
</template>

<script src="./LoginMfa.js"></script>

<style src="./LoginMfa.scss" lang="scss" scoped></style>
